import { useState, useEffect } from 'react';

/**
 * Awaits a promise, returns hook state to indicate progress
 * @param {function(): Promise} promise - Promise to resolve
 * @param {function(): Promise} [initial] - An optional initial promise to await before
 * starting to capture state
 * @returns {Object} Returns the state of the promise resolution
 */
const useAsyncData = (promise, initial) => {
    useEffect(() => {
        const fetchData = async () => {
            try {
                await initial;
                setState((s) => ({ ...s, fetching: true, initialising: false }));
                const data = await promise;
                if (data) setState((s) => ({ ...s, data }));
            } catch (error) {
                setState((s) => ({ ...s, error }));
            } finally {
                setState((s) => ({ ...s, fetching: false, initialising: false }));
            }
        };

        fetchData();
    }, [promise, initial]);

    const initState = { fetching: !initial, initialising: !!initial };
    const [state, setState] = useState(initState);
    return state;
};
export default useAsyncData;
