import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { useBrand } from '../../BrandContext';
import { withText } from '../../text/textStore';
import getConfig from '../../data/config';
import { BRANDS } from '@cof/plastic-components';

const DocumentHead = ({ Text: text }) => {
    const brand = useBrand();

    return (
        <Helmet>
            {brand === BRANDS.CAPITAL_ONE && (
                <link
                    rel="preload"
                    fetchPriority="high"
                    as="image"
                    href={'./assets/webp/capital-one-card.webp'}
                    type="image/webp"
                />
            )}
            <link rel="preconnect" href={getConfig('form').baseURL} />
            <link rel="icon" href={`/${brand}_favicon.ico`} />
            <title>{text({ path: 'Title' })}</title>
            <meta name="Description" content={text({ path: 'Description' })} />
        </Helmet>
    );
};

DocumentHead.propTypes = {
    Text: PropTypes.func.isRequired
};

export default withText('DocumentHead')(DocumentHead);
