export const timeout = (ms) =>
    new Promise((resolve) => {
        setTimeout(resolve, ms);
    });

export const safe = async (promise, errorCallback) => {
    let res;
    try {
        res = await promise;
    } catch (e) {
        if (errorCallback) errorCallback(e);
        res = undefined;
    }
    return res;
};
