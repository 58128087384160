import {
    AGGREGATOR_CONSUMER_NAMES,
    AGGREGATOR_WITH_LOGO,
    API_PARTNERS,
    HOSTED_PARTNERS,
    INSTANT_ISSUANCE_BRANDS
} from './constants';

export const isHosted = (brand) => {
    return HOSTED_PARTNERS.includes(brand);
};

export const hasAggregatorLogo = (consumerName) => {
    return AGGREGATOR_WITH_LOGO.includes(consumerName);
};

export const isAggregatorConsumer = (consumerName) => {
    return AGGREGATOR_CONSUMER_NAMES.includes(consumerName);
};

export const isApiPartner = (brand) => {
    return API_PARTNERS.includes(brand);
};

export const useSinglePage = (brand, consumerName) => {
    return isApiPartner(brand) || isAggregatorConsumer(consumerName);
};

export const isInstantIssuanceBrand = (brand) => {
    return INSTANT_ISSUANCE_BRANDS.some((partner) => brand === partner);
};

export const lookupWithDefault = (table, key, defaultKey = 'default') => table[key] || table[defaultKey];

export const addSParameterToLinkUrl = ({ url, sParameter }) => {
    const urlObj = new URL(url);
    if (sParameter) {
        urlObj.searchParams.set('s', sParameter);
    }
    return urlObj.toString();
};

export const maskParam = (url, param, maskValue = 'MASKED') => {
    const urlObj = new URL(url);
    if (urlObj.searchParams.get(param)) {
        urlObj.searchParams.set(param, maskValue);
    }
    return urlObj.toString();
};

export const maskAllHashParams = (url) => url.split('#')[0];

export const parseCookie = (k) =>
    document.cookie
        .split('; ')
        .find((row) => row.startsWith(`${k}=`))
        ?.split('=')[1];

export const removeAllWhitespace = (val) => val.replace(/\s+/g, '');

export const trimWhitespace = (val) => val.trim();
