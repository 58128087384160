import container from './container';

export const findMbox = (offers, mbox) => offers?.execute?.mboxes?.find((x) => x.name === mbox)?.options?.[0];

export const findVec = (offers) => offers?.execute?.pageLoad?.options?.[0];

const mbox = Object.keys(container)?.[0];

export const formTokens = (offers) => findMbox(offers, mbox)?.responseTokens;

export const vecTokens = (offers) => findVec(offers)?.responseTokens;

export const experimentParams = (offers) => {
    const tokens = vecTokens(offers) || formTokens(offers);

    if (!offers) return undefined;

    if (!tokens) return { at: {} };

    const value = {
        tntId: tokens['profile.tntId'],
        activityId: tokens['activity.id'],
        activityName: tokens['activity.name'],
        experienceId: tokens['experience.id'],
        experienceName: tokens['experience.name']
    };

    return { at: value };
};
