import { parseCookie } from '../../utilities/utils';
import { timeout } from '../../utilities/promises';

export const noConsentBailout = () => {
    if (!parseCookie('mbox')) {
        return Promise.resolve();
    } else {
        return timeout(5000);
    }
};
