import { useState } from 'react';
import axios from 'axios';

const useAxiosCallback = (configResolver) => {
    const [data, setData] = useState(undefined);
    const [response, setResponse] = useState(undefined);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [execCount, setExecCount] = useState(0);

    const fetchData = async (reqData, configResolver) => {
        try {
            setLoading(true);
            const params = await configResolver(reqData);
            const result = await axios.request(params);
            setResponse(result);
            setData(result.data);
        } catch (err) {
            setError(err);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const exec = (reqData) => {
        setExecCount(execCount + 1);
        fetchData(reqData, configResolver);
    };

    return [exec, loading, { error, response, data, execCount }];
};

export default useAxiosCallback;
