import PropTypes from 'prop-types';

import {
    LoadableASOS3dHorizontalImage,
    LoadableASOS3dVerticalImage,
    LoadableCapitalOne3dCardImage,
    LoadableLittlewoods3dCardImage,
    LoadableLuma3dCardImage,
    LoadableVery3dCardImage
} from '@cof/plastic-components';

import partners from '../../partners';

const { DEFAULT, VERY, LUMA, LITTLEWOODS, ASOS } = partners;

const HorizontalCardImage = ({ brand, ...props }) => {
    const HorizontalCardComponent = {
        [DEFAULT]: () => (
            <LoadableCapitalOne3dCardImage
                width={['0.53', null, '302px']}
                height={['0.53', null, '194px']}
                {...props}
            />
        ),
        [VERY]: () => (
            <LoadableVery3dCardImage width={['0.53', null, '302px']} height={['0.53', null, '194px']} {...props} />
        ),
        [LITTLEWOODS]: () => (
            <LoadableLittlewoods3dCardImage
                width={['0.53', null, '302px']}
                height={['0.53', null, '194px']}
                {...props}
            />
        ),
        [LUMA]: () => (
            <LoadableLuma3dCardImage width={['0.53', null, '302px']} height={['0.53', null, '194px']} {...props} />
        ),
        [ASOS]: () => (
            <LoadableASOS3dHorizontalImage
                width={['0.53', null, '0.96', '302px']}
                height={['0.53', null, '0.96', '194px']}
                {...props}
            />
        )
    }[brand || DEFAULT];

    return <HorizontalCardComponent {...props} />;
};
HorizontalCardImage.propTypes = {
    brand: PropTypes.string.isRequired
};

const VerticalCardComponent = {
    [ASOS]: LoadableASOS3dVerticalImage
};
const VerticalCardImage = ({ brand, ...props }) => {
    const CardComponent = VerticalCardComponent[brand];

    return <CardComponent {...props} />;
};
VerticalCardImage.propTypes = {
    brand: PropTypes.string.isRequired
};

const CardImage = ({ brand, vertical = false, ...props }) => {
    if (vertical && brand in VerticalCardComponent) {
        return (
            <VerticalCardImage
                brand={brand}
                width={['0.53', null, '0.96', '302px']}
                height={['0.53', null, '0.96', '194px']}
                {...props}
            />
        );
    }
    return <HorizontalCardImage brand={brand} {...props} />;
};
CardImage.propTypes = {
    brand: PropTypes.string.isRequired,
    vertical: PropTypes.bool
};

export default CardImage;
