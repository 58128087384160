/**
 * Adobe API client.
 *
 * @see Adobe Target getOffer and applyOffer docs at the link below
 * https://experienceleague.adobe.com/en/docs/target-dev/developer/client-side/at-js-implementation/functions-overview/adobe-target-getoffers-atjs-2
 *
 * Makes Adobe getOffers API call on module load for all configured mboxes for
 * Form Based Composer experiments, as well as pageLoad experiments configured
 * in Visual Experience Composer. The result of this call is resolved and memoized in offers.
 *
 * @see Manage flicker with getOffer and applyOffer at the link below
 * https://experienceleague.adobe.com/en/docs/target-dev/developer/client-side/at-js-implementation/at-js/manage-flicker-with-atjs
 * for why we are directly setting opacity here
 */

import container from '../../components/experiments/container';
import { maskAllHashParams, maskParam } from '../../utilities/utils';
const mboxes = Object.keys(container).map((x, i) => ({ index: i, name: x }));

const getOffers = window.onLoadTarget?.then(() =>
    window.adobe?.target?.getOffers({
        request: {
            context: {
                channel: 'web',
                crossDomain: 'disabled',
                address: { url: maskAllHashParams(maskParam(window.location.href, 'quotation')) }
            },
            execute: { pageLoad: {}, mboxes }
        }
    })
);

export const offers = getOffers
    ?.then((response) => {
        document.documentElement.style.opacity = '1';
        window.adobe?.target?.applyOffers({ response: response });
        return response;
    })
    .catch((err) => {
        document.documentElement.style.opacity = '1';
        throw new Error(err); // caught by consumers
    });
