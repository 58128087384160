import { useState } from 'react';
import config from '@cof/ukweb-config/lib/clientConfig';
import { useAxios, useAxiosCallback } from '../hooks/useAxios';
import { offers } from '../api/client/adobe';
import { noConsentBailout } from '../api/client/adobeParams';
import * as promises from '../utilities/promises';
import { experimentParams } from '../components/experiments/offers';
import getConfig from './config';

const ARTIFICIAL_DELAY = 1500;

export const useFormData = (params) => useAxios(() => ({ ...getConfig('form'), params }));

export const useFormSubmit = () => {
    const [delaying, setDelaying] = useState(false);
    const [submitForm, submitting, ...rest] = useAxiosCallback((data) => {
        setDelaying(true);
        setTimeout(() => setDelaying(false), ARTIFICIAL_DELAY);
        const { downstreamVersionIsEnabled } = config.get('useDownstreamVersion') || {};
        return withAsyncData(
            {
                ...getConfig('quotation'),
                withCredentials: downstreamVersionIsEnabled ? true : undefined,
                data,
                method: 'post'
            },
            adobeParams
        );
    });

    return [submitForm, submitting || delaying, ...rest];
};

export const useAddressLookupData = (sessionId) =>
    useAxiosCallback((data) => {
        const headers = { ...getConfig('addressLookup').headers, ...{ 'Session-Id': sessionId } };
        return { ...getConfig('addressLookup'), headers, data, method: 'post' };
    });

export const useStageData = (sessionId) =>
    useAxiosCallback((data) => {
        const headers = { ...getConfig('stage').headers, ...{ 'Session-Id': sessionId } };
        return { ...getConfig('stage'), headers, data, method: 'post' };
    });

export const shouldShowTechError = (brand) => {
    const { brands } = config.get('showTechError');
    return brands.map((x) => x.toLowerCase()).includes(brand.toLowerCase());
};

const adobeParams = Promise.any([offers, noConsentBailout()]).then(experimentParams);

export const withAsyncData = (originalPayload, data) =>
    promises.safe(data).then((p) => ({ ...originalPayload, data: { ...originalPayload.data, ...p } }));
