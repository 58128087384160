/**
 * Container dictionary of active Adobe form based composer experiments
 *
 * @example
 *
 * const container = {
 *   'quotations-batchrequote-mbox': {
 *       B: lazy(() => import('./variants/Sample/Sample')),
 *       C: EagerSample
 *   }
 * // where EagerSample is an imported React.FunctionComponent
 *
 * @type {{string: Object.<string, ReactNode>}}
 */
const container = {};

export default container;
