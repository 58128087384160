import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Paragraph } from '@cof/plastic-components';

import BodySection from '../../components/BodySection';
import Footer from '../../components/Footer';
import Main from '../../components/Main';
import TitleSection, { TitleSectionHeading } from '../../components/TitleSection';
import { withText } from '../../text/textStore';
import { addNewRelicError } from '../../utilities/newRelic';
import useBackground from '../../utilities/useBackground';

const TechnicalError = ({ Text }) => {
    useEffect(() => {
        addNewRelicError('technical-error', { sawError: true });
    }, []);

    const background = useBackground('error');

    return (
        <>
            <Main bg={background} data-qa-id="technical-error-page">
                <TitleSection>
                    <TitleSectionHeading>
                        <Text path="Title" />
                    </TitleSectionHeading>
                </TitleSection>
                <BodySection>
                    <Paragraph data-qa-id="tech-error-message">
                        <Text path="Body" />
                    </Paragraph>
                </BodySection>
            </Main>
            <Footer />
        </>
    );
};

TechnicalError.propTypes = {
    Text: PropTypes.func.isRequired
};

const WrappedTechnicalError = withText('TechnicalError')(TechnicalError);

WrappedTechnicalError.displayName = 'TechnicalError';

export default WrappedTechnicalError;
